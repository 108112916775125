<template>
  <v-card flat class="my-2">
    <v-card-title class="text-h6 font-weight-bold">
      {{ store_add_preferencias ? "Adicionar" : "Editar" }} Tema
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      class="px-6"
      v-for="(tema, index) in Object.keys(theme)"
      :key="index"
    >
      <h6 class="font-weight-bold text-subtitle-1">{{ tema.toUpperCase() }}</h6>
      <v-row>
        <v-col
          cols="auto"
          v-for="(key, index) in Object.keys(theme[tema])"
          :key="index"
        >
          <v-text-field v-model="theme[tema][key]" :label="key">
            <template v-slot:prepend>
              <v-sheet
                class="rounded-circle"
                width="40px"
                height="40px"
                :style="`background-color: ${theme[tema][key]}`"
              >
              </v-sheet>
            </template>
            <template v-slot:append>
              <v-menu offset-x :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-palette</v-icon>
                  </v-btn>
                </template>
                <v-color-picker
                  hide-canvas
                  show-swatches
                  hide-inputs
                  mode="hexa"
                  v-model="theme[tema][key]"
                ></v-color-picker>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-6">
      <v-spacer></v-spacer>
      <v-btn
        :disabled="store_add_preferencias"
        @click="resetTheme"
        class="mr-3"
        outlined
      >
        Resetar
      </v-btn>
      <v-btn
        @click="
          store_add_preferencias
            ? createPreferences(theme)
            : updatePreferences(theme)
        "
        color="secondary"
      >
        {{ store_add_preferencias ? "adicionar" : "salvar" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ThemeChanger",

  data() {
    return {
      menu: false,
      theme: {},
    };
  },

  computed: {
    ...mapState("Usuario", {
      store_user_id: (state) => state.usuario.id,
    }),

    ...mapState("Preferencias", {
      store_preferencias: (state) => state.preferencias,
      store_theme: (state) => state.theme,
      store_theme_original: (state) => state.themeOriginal,
      store_add_preferencias: (state) => state.add_preferencias,
    }),
  },

  watch: {
    store_theme: {
      handler() {
        if (this.store_theme) {
          this.theme = this.store_theme;
        } else {
          this.theme = this.store_theme_original;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("Preferencias", {
      STORE_updatePreferencias: "updatePreferencias",
      STORE_addPreferencias: "addPreferencias",
      STORE_resetTheme: "resetTheme",
    }),

    resetTheme() {
      this.STORE_resetTheme().then(() => {
        this.$toast.success("Preferencias resetadas com sucesso!");
      });
    },

    updatePreferences(theme) {
      let preferencias = {};
      preferencias.id = this.store_preferencias.id;
      preferencias.user_id = this.store_preferencias.user_id;
      preferencias.theme = JSON.stringify(theme);

      this.STORE_updatePreferencias({
        id: preferencias.id,
        preferencias: preferencias,
      }).then(() => {
        this.$toast.success("Preferencias atualizadas com sucesso!");
      });
    },

    createPreferences(theme) {
      let preferencias = {};
      preferencias.user_id = this.store_user_id;
      preferencias.theme = JSON.stringify(theme);

      this.STORE_addPreferencias(preferencias).then(() => {
        this.$toast.success("Preferencias criadas com sucesso!");
      });
    },
  },
};
</script>
